import React from "react";
import "./statement.css";

const Statement = () => {
    return (
        <section className="statement-container" id="statement">
            <h1>Missão</h1>
            <h2> “Devolver a vida aos pacientes que sofrem com doenças cerebrais e da coluna,
                tratando verdadeiramente das pessoas através da escuta ativa, do exame físico cuidadoso e da avaliação do indivíduo como um todo.”
            </h2>
        </section>
    );
};

export default Statement;
