import React from 'react';
import MinhaImagem from "../../Assets/sobre-magno.jpg";
import './apresentacao.css';

const ImageWithText = () => {
    return (
        <section className="apresentacao" id="apresentacao">
            <div className='retrato'>
                <img src={MinhaImagem} alt="Retrato do Doutor" className="imagem" aria-label="Foto do Dr. Magno Rosa, neurocirurgião" />
            </div>
            <div className='descricao' data-aos="fade-down" data-aos-easing="ease-out-cubic" data-aos-duration="2500">
                <h1 id="nome-medico">DR. MAGNO ROSA</h1>
                <h2>NEUROCIRURGIÃO</h2>
                <h3>CRM: 52-113331-4 | RQE: 50.912</h3>
                <p>Cirurgião de referência, com ampla experiência no tratamento de doenças do cérebro, da coluna, dos nervos e da dor</p>
                <p id="descricao-medico">
                    Médico formado pela Universidade do Estado do Rio de Janeiro (UERJ).
                    Especialização em Neurocirurgia pelo Hospital Universitário Pedro Ernesto (HUPE/UERJ).</p>
                <p>

                    Mestrado em Medicina pelo Programa de Pós Graduação em Ciências Médicas da UERJ, tendo estudado o procedimento de reconstrução da meninge em pacientes submetidos a neurocirurgia.</p>
                <p>
                    Palestrante recorrente em congressos de Neurocirurgia, incluindo o congresso mundial de cirurgias de base de crânio (Skull Base Societies Meeting) e congresso da sociedade de Neurocirurgia do Rio de Janeiro (SNCRJ), apresentando trabalhos também no Congresso Brasileiro de Neurocirurgia (CBN).</p>
            </div>
        </section >
    );
}

export default ImageWithText;
