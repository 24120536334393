import React from "react";
import "./whatsapp.css";
import whatsappIcon from "../../Assets/whatsapp-64.ico";

const Whatsapp = () => {

    const contatoViaBotaoVoador = (url) => {
        var callback = function () { if (typeof (url) !== 'undefined') { window.location = url; } };
        window.gtag('event', 'conversion', { 'send_to': 'AW-11110162629/ErfyCJmslqoZEMXB3rEp', 'event_callback': callback, 'event_label': 'contato_flutuante' });
        return false;
    };

    return (
        <>
            <a
                href="https://api.whatsapp.com/send?phone=5521998191616&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais"
                className="float"
                target="_blank"
                rel="noreferrer"
                onClick={() => contatoViaBotaoVoador('https://api.whatsapp.com/send?phone=5521998191616&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais')}
            >
                <img src={whatsappIcon} alt="Whatsapp Icon" />
            </a>
        </>
    );
};

export default Whatsapp;
