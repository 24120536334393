import React from 'react';
import Coluna from "../../Assets/coluna.webp";
import Cranio from "../../Assets/cranio.webp";
import Dor from "../../Assets/dor.webp";
import './especialidades.css';

const Especialidades = () => {
  return (
    <section id='especialidades'>
      <div className='title'>
        <h1>ESPECIALIDADES</h1>
      </div>
      <div className='especialidades-container'>
        <div className='item'>
          <div className='text-item' data-aos="fade-down" data-aos-easing="ease-out-cubic" data-aos-duration="2500">
            <h2>CRÂNIO</h2>
            <p>
              Dentro das patologias de crânio, tem grande experiência no tratamento microcirúrgico e endoscópico minimamente invasivos de tumores e outras lesões expansivas cerebrais pediátricas e de adultos, hidrocefalia e das doenças vasculares, como os aneurismas cerebrais.
            </p>
            <p>
              Também tem experiência em cirurgias para Doença de Parkinson e outros transtornos do movimento, tendo atuado no grupo de cirurgias para transtornos do movimento do Hospital Universitário Pedro Ernesto da UERJ(NIPDIM).
            </p>
            <p>
              Treinamento em Microcirurgia pelo Laboratório de Microcirurgia da Beneficência Portuguesa de São Paulo/Instituto de Ciências Neurológicas de São Paulo.
            </p>
          </div>
        </div>
        <div className='item'>
          <img src={Cranio} alt="Tratamento de crânio" />
        </div>
        <div className='item'>
          <img src={Coluna} alt="Tratamento de coluna" />
        </div>
        <div className='item'>
          <div className='text-item' data-aos="fade-down" data-aos-easing="ease-out-cubic" data-aos-duration="2500">
            <h2>COLUNA</h2>
            <p>
              Experiência em cirurgias complexas de coluna, tendo atuado no grupo de cirurgias de coluna do Hospital Universitário Pedro Ernesto da UERJ (NIPNAC - Núcleo de Internação de Pacientes Neurocirúrgicos de Alta Complexidade).
            </p>
            <p>
              Realiza cirurgias minimamente invasivas para tratamento de doença degenerativa da coluna lombar, torácica e cervical, como hérnias de disco, estenose de canal e espondilolistese, com e sem instrumentação (artrodese).
            </p>
          </div>
        </div>
        <div className='item'>
          <div className='text-item' data-aos="fade-down" data-aos-easing="ease-out-cubic" data-aos-duration="2500">
            <h2>DOR</h2>
            <p>
              Experiência vasta no tratamento da dor aguda e crônica, nas patologias de coluna e em outros tipos de dor, como as dores crânio-faciais e a Neuralgia do Trigêmeo.
            </p>
            <p>
              Realiza diversos procedimentos de caráter intervencionista percutâneos e minimamente invasivos para o tratamento de pacientes com dor.
            </p>
          </div>
        </div>
        <div className='item'>
          <img src={Dor} alt="Tratamento da dor" />
        </div>
      </div>
    </section>
  );
};

export default Especialidades;
