import React, { useState, useEffect } from "react";
import "./navbar.css";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      // Close the menu when a link is clicked
      setIsMenuOpen(false);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav id="navbar" className={`${scrolled ? "scrolled" : ""} ${isMenuOpen ? "open" : ""}`}>
      <div className="nav-logo-container">
        <h1>DR. MAGNO ROSA | NEUROCIRURGIÃO</h1>
      </div>
      <div className="navbar-links-container">
        <div className="menu-icon" onClick={toggleMenu} aria-label="Abrir menu de navegação">
          <div className="menu-line"></div>
          <div className="menu-line"></div>
          <div className="menu-line"></div>
        </div>
        <div className="navbar-links">
          <a href="#home" onClick={() => scrollToSection("home")} role="button">HOME</a>
          <a href="#apresentacao" onClick={() => scrollToSection("apresentacao")} role="button">SOBRE MIM</a>
          <a href="#especialidades" onClick={() => scrollToSection("especialidades")} role="button">ESPECIALIDADES</a>
          <a href="#atendimento" onClick={() => scrollToSection("atendimento")} role="button">ATENDIMENTO</a>
          <a href="#contato" onClick={() => scrollToSection("contato")} role="button">CONTATO</a>
          <a href="https://www.instagram.com/neuromagnorosa" target="_blank" rel="noopener noreferrer">INSTAGRAM</a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
