import React from 'react';
import './contato.css';
import Email from "../../Assets/email.png";
import Wpp from "../../Assets/wpp.svg";
import Instagram from "../../Assets/instagram.png";
import Doctoralia from "../../Assets/doctoralia.png";

const Contato = () => {
  const contatoViaWhatsappCard = (url) => {
    var callback = function () { if (typeof (url) !== 'undefined') { window.location = url; } };
    window.gtag('event', 'conversion', { 'send_to': 'AW-11110162629/ErfyCJmslqoZEMXB3rEp', 'event_callback': callback, 'event_label': 'contato_card' });
    return false;
  };

  return (
    <section className="contato" id="contato">
      <div className='title'>
        <h1>CONTATO</h1>
      </div>
      <div className="contato-area">
        <a href="https://api.whatsapp.com/send?phone=5521998191616&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais" className="contato-card" aria-label="Link para o Whatsapp" onClick={() => contatoViaWhatsappCard("https://api.whatsapp.com/send?phone=5521998191616&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais")}>
          <img src={Wpp} alt="Whatsapp Icon" />
          <h3>Whatsapp</h3>
          <p>(21) 99819-1616</p>
        </a>

        <a href="https://www.instagram.com/neuromagnorosa" target="_blank" rel="noopener noreferrer" className="contato-card" aria-label="Link para o Instagram">
          <img src={Instagram} alt="Instagram Icon" />
          <h3>Instagram</h3>
          <p>@neuromagnorosa</p>
        </a>

        <a href="https://www.doctoralia.com.br/magno-rosa/neurocirurgiao/rio-de-janeiro" className="contato-card" aria-label="Link para o Doctoralia">
          <img src={Doctoralia} alt="Doctoralia Icon" />
          <h3>Doctoralia</h3>
          <p>Dr Magno Rosa</p>
        </a>

        <a href="mailto:neuromagnorosa@gmail.com" className="contato-card" aria-label="Link para o Email">
          <img src={Email} alt="Email Icon" />
          <h3>Email</h3>
          <p>neuromagnorosa@gmail.com</p>
        </a>
      </div>
    </section>
  );
};

export default Contato;
